<template>
  <!-- Result masonry -->
  <div class="result__masonry" v-show="inSearch && !inKeywordSearch">
    <div class="page inner-container result">
      <div class="page-wrap">
        <div class="result--content">
          <div class="row">
            <div class="col-12 col-lg-4">
              <div class="result--itemWrap">
                <p v-if="!this.inTaxonomySearch">
                  {{ resultCount }} Result of
                  {{ searchArray.title.join(", ") | capitalize }}
                </p>

                <p v-else>
                  {{ resultCount }} Result of "{{ this.taxonomyType.title }}"
                </p>

                <h3 v-show="toShowOnHover">
                  {{ recordShowItem.title_en }}
                </h3>
              </div>
            </div>
            <div class="col-12 col-lg-8">
              <div class="contact--itemWrap ">
                <div
                  v-masonry=""
                  transition-duration="0"
                  item-selector=".item"
                  column-width=".item__sizer"
                  percent-position="true"
                  gutter=".item__gutter"
                  id="resultMasonry"
                >
                  <div class="item__sizer"></div>
                  <div class="item__gutter"></div>

                  <div
                    v-masonry-tile
                    v-for="(item, index) in resultList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        name: 'Record Details',
                        params: { slug: item.slug }
                      }"
                    >
                      <SingleItem
                        :name="item.title_en"
                        :imageName="item.gallery[0].image"
                        :sizeType="item.sizeType"
                        :hoverFunction="true"
                        :relatedItem="false"
                        @updateShowRecord="updatedRecordShowItem(item)"
                        @updateCursor="moveCursor"
                        :shy="toShowOnHover"
                      ></SingleItem
                    ></router-link>
                  </div>
                </div>
                <!-- <ul>
										<li v-for="(item, index) in resultList" :key="index">
											{{ item.title_en }}
										</li>
									</ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="custom__cursor"
      :style="{ top: cursorPos.y + 'px', left: cursorPos.x + 'px' }"
      v-show="cursorShow"
    >
      Discover More
    </div>
  </div>
  <!-- End result masonry -->
</template>
<script>
import SingleItem from "@/components/masonry/SingleItem";

export default {
  name: "resultMasonry",
  components: { SingleItem },
  data() {
    return {};
  },
  beforeMount() {},

  methods: {}
};
</script>

<style lang="scss" scoped></style>
